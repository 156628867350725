body{
    background-color: #ffd567;
    
}

*{
    color: black !important;
}

.btn-default{
    background-color: #f2f2f2;
    width: 30%;
    font-size: 16px;
    font-weight: bold;
    color: black;
}