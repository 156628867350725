.containerBlack{
    width: 100%;
    padding: 20px;
    background-color: black;
}

.container{
    width: 100%;
    padding: 20px;
    background-color: #ffd567;
}

.border{
    border-bottom: 10px solid #f2f2f2;
    margin-bottom: 20px;
}

.borderBlack{
    border-bottom: 10px solid black;
}